import request from '@/request/index.js'
import { ElMessage, ElLoading } from 'element-plus'
export function loading() {
    return ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(191, 220, 241, 0.1)',
    })
}
//列表接口
export function renderTable(url, params, tableData, total, load) {
    request({
        method: 'get',
        url: url,
        params: params.value
    }).then(data => {
        tableData.value = data.content;
        total.value = data.totalElements;
        load.value = false;
    }).catch(err => {
        console.log(err)
        load.value = false;
    })
}
//列表接口
export function renderTable_C(url, params, tableData) {
    request({
        method: 'get',
        url: url,
        params: params === null ? null : params.value
    }).then(data => {
        console.log(data)
        tableData.value = data;
    }).catch(err => {
        console.log(err)
    })
}

//新增数据
export function insertDate(url, data, dialogFormVisible, getDate) {
    let loding = loading()
    request({
        method: 'post',
        url: url,
        data
    }).then(data => {
        //关闭表单
        dialogFormVisible.value = false
        loding.close();
        //渲染表格
        getDate();
        ElMessage({ message: "操作成功", type: "success" });
    }).catch(err => {
        loding.close();
        console.log(err)
    });
}


//删除数据
//
export function delDate(url, getDate) {
    request({
        method: 'Delete',
        url: url
    }).then(data => {
        //渲染表格
        getDate();
        ElMessage({ message: "删除成功", type: "success" });
    }).catch(err => {
        console.log(err);
    })
}
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

//统一下载
export function export_base(filename, url, params) {
    // 在需要的地方再次调整超时时间
    request.defaults.timeout = 10000 * 20; // 将超时时间调整为20秒
    //get方式
    request({
        method: 'get',
        url: url,
        params,
        responseType: 'blob',
    }).then((data) => {
        const content = data
        console.log(data)
        console.log(data.type)
        if (data.type === "application/json") {
            const fileReader = new FileReader();
            fileReader.readAsText(data, "utf-8");
            fileReader.onload = () => {
                const result = fileReader.result;
                const msg = result ? JSON.parse(result).msg : "";
                console.log(result, msg);
                ElMessage({ message: "导出失败" + msg, type: "error" });
            };
            return;
        }
        const blob = new Blob([content])
        if ('download' in document.createElement('a')) {
            const elink = document.createElement('a')
            elink.download = generateUUID() + "_" + filename
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
        } else {
            navigator.msSaveBlob(blob, filename)
        }
    }).catch((r) => {
        console.error(r)
    })
}
