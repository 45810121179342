import { createApp } from 'vue'
import App from './App.vue'
//引入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from  'element-plus/es/locale/lang/zh-cn'

//引入font-awesome图标库
import 'font-awesome/css/font-awesome.min.css'
//引用路由
import router from '@/permission'
//引入vuex
import store from '@/store'
//引入封装的路由
import '@/request/index.js'

const app = createApp(App)
app
.use(router)
.use(ElementPlus,{locale})
.use(store)
.mount('#app')

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
      let context = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    }
  }
   
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
      callback = debounce(callback, 16);
      super(callback);
    }
  }