import { createRouter, createWebHistory } from "vue-router";
//静态路由
export const staticRoutes = [
    {
        path: '/',
        redirect: '/login',
        hidden: true,
        component: () => import('@/components/Login')
    },
    {
        path: '/login',
        // name:'/login',
        hidden: true,
        component: () => import('@/components/Login')
    },
    {
        path: '/home',
        meta: {
            hiddenBreadcrumb: true,
        },
        redirect: '/home/index',
        // component: resolve => require(['@/components/Home'],resolve) //异步组件
        component: () => import('@/components/Home'), //懒加载
        children: [
            {
                path: '/home/index',
                meta: {
                    hiddenBreadcrumb: true,
                },
                component: () => import('@/components/index') //懒加载
            },

        ]
    },
    {
        path: "/:pathMatch(.*)*", // 解决路由爆[Vue Router warn]: No match found for location with path
        meta: {
          title: "找不到此页面",
        },
        // redirect: '/403', // 错误方式，刷新立马会导致进入守卫的页面
        component: () => import("@/components/404"), // 切记不要使用 redirect: '/403',
      },

];

export default createRouter({
    history: createWebHistory(),
    routes: staticRoutes,
})

